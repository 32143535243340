import React, { useState, useEffect } from "react";
import styles from "./Fileupload.module.css";
import FileattatchLogo from "./fileattach.png";
import ProgressBar from "../../../assets/icons/progressbar.gif";
import { useDispatch } from "react-redux";
import { ErrorSetfunc } from "../../../ReduxStore/action";
import CustomButton from "../../../UI/CustomButton/CustomButton";
import { useSelector } from "react-redux";

import axios from "axios";
import { API_URL, WS_URL } from "../../../ConstVariable";
import Logger from "../../../Methods/Logger";

const checkInternetConnection = () => {
  return navigator.onLine;
};
const Fileupload = (props) => {
  const dispatch = useDispatch();
  const {activeButtonColor,activeButtonTextColor,inactiveButtonColor,inactiveButtonTextColor} = useSelector((state) => state.Theme);
  const { wsClient, staticData,connectionStatus,iswebSocketTryingToReConnect } = useSelector((state) => state.Reducer);
  // console.log("🚀 ~ file: Fileupload.js ~ line 14 ~ Fileupload ~ wsClient", wsClient)

  const [showUploadingBar, setUploadingBar] = useState(false);
  const [showLoading, setShowLoading] = useState("");

  const [isNetworkError, setIsNetworkError] = useState(false);
  const [referralUploadTimeout, setReferralUploadTime] = useState(false)
  const [showModal, setShowModal] = useState("");
  const [newImage, setNewImage] = useState(props.image);
  const [fileInput, setFileInput] = useState(null);
  const [counter, setCounter] = useState(5);
  const [shouldTimerRun, setShouldTimerRun] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const fileHandler = (e) => {
    const images = Array.from(e.target.files);
    props.setSendImage(images);
    setNewImage(e.target.files[0]);
    props.setImage(e.target.files[0]);
  };

  const reportDelayMessage = () => {
    let request_data = {
      conversation_id: props.conversationId,
      error_code: "REFERRAL_UPLOAD_ERROR",
      expected_response_for_event: "",
    };
    axios({
      method: "post",
      url: `${API_URL}/api/v1/error`,
      data: request_data,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.log("Error reported successfully", response.data);
      })
      .catch((error) => {
        console.log("Failed to report error");
      });
  };

  const referralUploadError = () => {
    reportDelayMessage();

    let requestData = {
      uid: props.conversationId,
      event: "REFERRAL_UPLOAD_ERROR",
      log_type: "ERROR",
      data: "Referral form upload failed",
      source: "SYSTEM",
    };
    Logger(requestData)
      .then((response) => response.json())
      .catch((error) => {
        console.log(error);
      });

    let Ai_response = {
      type: "REFERRAL_UPLOAD_ERROR",
      message: staticData?.referral_upload_error_info,
      icon: props.image,
    };

    props.actionProvider.showResponseDialog(Ai_response);
  };

  const handleAIResponse = (webSocketMessage) => {
    let ai_response = JSON.parse(webSocketMessage.data);

    // if (ai_response.type === "HANG_UP") {
    //   props.wsClient.handle_User_CloseConnectionRequest();
    // }

    props.actionProvider.showResponseDialog(ai_response);
  };

  const UploadHandler = async (conversation_id) => {
    // Getting the URL
    // if we got an internet error then retry to call this
    props.setDisable(false);
    setIsNetworkError(false);
    setReferralUploadTime(false)


    let split_url = WS_URL.split("//");
    console.log("split_url", split_url, WS_URL);
    let url = null;
    if (split_url[0] === "ws:") {
      url = WS_URL.replace("ws", "http");
    } else {
      url = WS_URL.replace("wss", "https");
    }

    setUploadingBar(true);
    var formdata = new FormData();

    for (const file of props.sendImage) {
      formdata.append("files", file);
    }

    // console.log("FormData", formdata);
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    // `appointment.webbot.vengage.ai/api/v1/upload/wb/referral${props.conversationId}`,
    // "https://dev-test.ngrok.io/oi/api/referral/upload/files?conversation_id="
    // voicetest.vengage.ai

    axios({
      method: "post",
      url: `${url}/oi/api/referral/upload/files?conversation_id=${props.conversationId}`,
      data: formdata,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      timeout:30000
    })
      .then((response) => {
        const { error_code } = response.data;
        setIsNetworkError(false);

        setShowLoading("none");
        setUploadingBar(false);

        if (parseInt(error_code) === 0) {
          let message = {
            event: "REFERRAL_LETTER",
            uid: props.conversationId,
          };

          props.wsClient.handleUserResponse(message, handleAIResponse);
          props.actionProvider.showResponseDialog({
            type: "SHOWFILE",
            image: props.image,
          });

          wsClient.resetResponseDelayErrorVariables();
        } else {
          // dispatch(ErrorSetfunc("REFERRAL_UPLOAD_ERROR"));
          referralUploadError();

          console.log(" Else Error ");
        }
        props.setDisable(true);
        setShouldTimerRun(false);
      })
      .catch((err) => {
        if (err) {
          checkInternetConnection()
            .then(() => {
              console.error(err);
              
              if(err.message.includes('timeout')){
                // setIsNetworkError(true);
                setReferralUploadTime(true)
                setUploadingBar(false);
                setShowModal("");
              }

              else if (err.response) {
                setUploadingBar(false);
                props.setDisable(true);
                referralUploadError();
                setShouldTimerRun(false);
              setShowModal("none");

                // Must be an API Error since the server sent a response
              } else {
                setIsNetworkError(true);
                setUploadingBar(false);
                setShowModal("");
              }
            })
            .catch((error) => {
              alert("Network Issue")
              setIsNetworkError(true);
              setUploadingBar(false);
              setShowModal("");
            });

          setUploadingBar(false);
        }
      });
  };

  // Run a timer to retry the internet connection
  useEffect(() => {
    if (shouldTimerRun) {
      let intervalid = setTimeout(() => {
        let count = counter - 1;
        setCounter(() => count);
        if (count === 0) {
          clearTimeout(intervalid);
          setCounter(5);
          setShouldTimerRun(false);
          UploadHandler();
        }
      }, 1000);
      setIntervalId(intervalid);
    }
    return () => {
      clearTimeout(intervalId);
    };
  }, [counter, shouldTimerRun]);

  const handleBtnEvent = () => {
    UploadHandler();

    setShowModal("none");
  };

  return (
    <div>
      <div
        className={styles.vengage__fileUpload}
        style={{ display: showModal }}
      >
        <div className={styles.vengage__fileUpload__showpreview}>
          {props.image !== undefined &&
          props.image.type == "application/pdf" ? (
            <img
              src={FileattatchLogo}
              style={{ width: "80px", height: "80px" }}
            />
          ) : (
            props.image !== undefined && (
              <img
                src={URL.createObjectURL(props.image)}
                style={{ width: "80px", height: "80px" }}
              />
            )
          )}
        </div>
        <div className={styles.vengage__fileUpload__button}>
          <CustomButton
            text={props.confirmatrionText}
            clickHandler={handleBtnEvent}
            disabled={iswebSocketTryingToReConnect?iswebSocketTryingToReConnect:props.disableAllButton}
          />
          <button
            type="button"
            className={styles.vengage__btn__primary}
            onClick={() => fileInput.click()}
            style={{  backgroundColor:connectionStatus==""?(activeButtonColor!=null?activeButtonColor:null):inactiveButtonColor,
              color:connectionStatus==""?(activeButtonTextColor!=null?activeButtonTextColor:null):inactiveButtonTextColor }}
              disabled={connectionStatus!=""?true:false }
          >
            {props.attach_referral_again_text} 
            <input
              type="file"
              ref={(fileInputref) => setFileInput(fileInputref)}
              multiple
              onChange={fileHandler}
              style={{ display: "none" }}
            />
          </button>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {showUploadingBar && (
          <img
            src={ProgressBar}
            // src="https://appointment-webbot.vengage.ai/static/media/progressbar.13e05ff3.gif"
            alt="Uploading the file ..."
            style={{ width: "80px" }}
          />
        )}
        {/* <div className={styles.retry__button} >
        <button className={styles.button} > Retry to upload </button>

        </div> */}
        {isNetworkError ? (
          <div className={styles.network__error}>
            {" "}
            <i>
              {" "}
              Unable to upload Referral currently due to network error. Please
              check internet connection and click on <b> Confirm</b>.{" "}
            </i>{" "}
          </div>
        ) : (
          ""
        )}
        {
          referralUploadTimeout? 
          (<div className={styles.network__error}>
            {" "}
            <i>
              {" "}
              We got unexpected error. Please click on <b> Confirm</b> button to upload referral form.{" "}
            </i>{" "}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Fileupload;
