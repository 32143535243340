
import { createChatBotMessage } from "react-chatbot-kit";
import { v4 as uuidv4 } from "uuid";
import ChatBotAvatar from "../bot/widgets//ChatBotAvatar";
import AskConfirmation from "../bot/widgets/askConfirmation/AskConfirmation";
import BookingOverview from "../bot/widgets/BookingOverview";
import UpdateButtion from "../bot/widgets/BookingUpdateButton/UpdateButtion";
import BtnOptions from "../bot/widgets/BtnOptions";
import Calendar from "../bot/widgets/Calendar";
import ChatBotHeader from "../bot/widgets/ChatBotHeader";
import CitySelection from "../bot/widgets/CitySelection/index";
import ConversationTermination from "../bot/widgets/ConversationTermination/ConversationTermination";
import Customdatepicker from "../bot/widgets/DatePicker/Customdatepicker";
import DoctorAvailablity from "../bot/widgets/DoctorAvailablity/DoctorAvailablity";
import WebSocketError from "../bot/widgets/ErrorHandler/WebSocketError";
import Fileupload from "../bot/widgets/Fileupload/Fileupload";
import Fileuploadbutton from "../bot/widgets/FileuploadButton/Fileuploadbutton";
import LanguageSelection from "../bot/widgets/languageSelection/LanguageSelection";
import MessageParserDocs from "../bot/widgets/MessageParserDocs/MessageParserDocs";
import Options from "../bot/widgets/Overview/Options";
import Overview from "../bot/widgets/Overview/Overview";
import PatientDetails from "../bot/widgets/PatientDetailsUpadate/PatientDetails";
import Patientscanetype from "../bot/widgets/PatientDetailsUpadate/Patientscanetype";
import Reappointment from "../bot/widgets/restartConversation/Reappointment";
import Suggestions from "../bot/widgets/Suggestions";
import GPCalendarWidget from "../bot/widgets/CalendarWidget/GPCalendarWidget";
import TimeOutNotification from "../bot/widgets/TimeOutMessage/TimeOutNotification";
import WidgetDocs from "../bot/widgets/WidgetDocs/WidgetDocs";
import parse from "html-react-parser";
import LanguageStore from "./LanguageStore";
import UnknownResponseTypeHandle from "../bot/widgets/unKnownResponseTypeHandle/UnknownResponseTypeHandle";
import ImagingMultiCenterCalendar from "../bot/widgets/imagingMultiCenterCalendar/ImagingMultiCenterCalendar";
import ImagingSlotPicker from "../bot/widgets/ImagingSlotPicker/ImagingSlotPicker";
import ImagingCalendarWidget from "../bot/widgets/CalendarWidget/ImagingCalendarWidget";
import MyUserChatMessage from "../bot/widgets/MyUserChatMessage/MyUserChatMessage";

const BotConfig={
    getConfig: (props,primaryColor,bannerTitle) => {
        let chatBotInitialMessage = null;

        if (LanguageStore.languages.length === 1) {
            chatBotInitialMessage = createChatBotMessage(
                <span>
                    {parse("Hi, my name is <b>Olivia</b>, how can I help you ? Please select one of the options")}
                </span>,
                {
                    //NOTE:- withAvatar tells that Bot icon should show up or not if withAvata is true than chatBot icon will show
                    withAvatar: true,
                    widget: "overview",
                }
            )
        }
        else {
            chatBotInitialMessage = createChatBotMessage(
                <span>
                    {LanguageStore.getDisplayText()}
                </span>,
                {
                    //NOTE:- withAvatar tells that Bot icon should show up or not if withAvata is true than chatBot icon will show
                    withAvatar: true,
                    widget: "languageSelection",
                }
            )
        }
        let config = {
            // botName: botName,
            lang: "no",

            // NOTE:- In customStyles , we changes botMessageBox's BG color and ChatButton's color
            customStyles: {
                botMessageBox: {
                    backgroundColor: "#fff",
                },
                chatButton: {
                    backgroundColor: primaryColor
                },
            },

            //NOTE:- initialMessages will show up when chatBot render first time and also render the component Overview

            initialMessages: [chatBotInitialMessage],
            state: {
                gist: "",
                infoBox: "",
                presetUserResponses: [],
                chatBotHeaderDefault: true,
                step: "",
                header: "",
                defaultOptions: [],
                workingDays: [],
                conversationId: uuidv4(),
                connectedClients: 0,
                connectionStatus: "Not Connected",
                wsClient: null,
                modalityConfirmButton: "",
                centerList: "",
                availableSlots: "",
                userDetails: "",
                updateButtons: "",
                fileUploadButton: "",
                restartHandler: true, //resetHandler tells that if false then new event will go to the sever with connetced from overview component
                fileStore: "",
                Switch_Input: "",
                Mobile_No: "",
                Date: "",
                //GP:WorkFlow
                workflowType: "",
                inputDisable: "",
                doctorAvailablityData: "",
                captureEventType: "",
                previousCaptureEventType: "",
                conversationCloseCountDownTime: "",
                disableAllButton: false,
                showTheCountDown: false,
                input_box_placeholder: "",
                meet_olivia_text: "",
                restart_chat_text: "",
                doctor_list_filter_placeholder_text: "",
                confirm_selection: "",
                deny_selection: "",
                selected_time_text: "",
                confirmatrionText: "",
                attach_referral_again_text: "",
                referral_upload_options: "",
                send_link_to_mobile_message: "",
                multi_center_tab_options:[],
                selected_tab_id:null,
                event_to_be_returned_by_UI:null,
                center_id:null
            },

            customComponents: {
                botAvatar: (props) => <ChatBotAvatar {...props} />,
                userAvatar: (props) => { },
                //NOTE:-header , here we change the chatBot header name and title
                // here in haeder component we are passing the props and config state
                header: (props, state) => (
                    <ChatBotHeader
                        title={"Meet Olivia"}
                        subtitle={"How may I help you…"}
                        {...props}
                        {...state}
                    />
                ),
                userChatMessage: (props) => < MyUserChatMessage {...props} />
            },

            widgets: [
                {
                    widgetName: "overview",
                    widgetFunc: (props) => <Overview {...props} />,
                    //mapStateToProps tells the all the strings between into the array are props. these strings will pass to Overview component
                    mapStateToProps: [
                        "wsClient",
                        "conversationId",
                        "connectionStatus",
                        "defaultOptions",
                        "restartHandler",
                        "captureEventType",
                        "previousCaptureEventType",
                        "disableAllButton",
                        "showTheCountDown",
                        "conversationCloseCountDownTime",
                        "input_box_placeholder",
                        "meet_olivia_text",
                        "restart_chat_text"
                    ],
                },
                {
                    widgetName: "bookingOverview",
                    widgetFunc: (props) => <BookingOverview {...props} />,
                    mapStateToProps: [
                        "wsClient",
                        "conversationId",
                        "connectionStatus",
                        "userDetails",
                        "workflowType",
                        "captureEventType",
                        "disableAllButton"

                    ],
                },
                {
                    widgetName: "btnOptions",
                    widgetFunc: (props) => <BtnOptions {...props} />,
                    mapStateToProps: [
                        "wsClient",
                        "conversationId",
                        "connectionStatus",
                        "modalityConfirmButton",
                        "workflowType",
                        "captureEventType",
                        "disableAllButton"

                    ],
                },
                {
                    widgetName: "calendar",
                    widgetFunc: (props) => <Calendar {...props} />,
                    mapStateToProps: [
                        "wsClient",
                        "conversationId",
                        "connectionStatus",
                        "availableSlots",
                        "captureEventType",
                        "disableAllButton"

                    ],
                },
                {
                    widgetName: "multiCenterTimePicker",
                    widgetFunc: (props) => <ImagingSlotPicker {...props} />,
                    mapStateToProps: [
                        "wsClient",
                        "conversationId",
                        "connectionStatus",
                        "availableSlots",
                        "captureEventType",
                        "disableAllButton",
                        "Date",
                        "event_to_be_returned_by_UI",
                        "center_id"



                    ],
                },

                {
                    widgetName: "messageParser",
                    widgetFunc: (props) => <MessageParserDocs {...props} />,
                    mapStateToProps: ["gist", "infoBox"],
                },
                {
                    widgetName: "citySelection",
                    widgetFunc: (props) => <CitySelection {...props} />,
                    mapStateToProps: [
                        "wsClient",
                        "conversationId",
                        "connectionStatus",
                        "centerList",
                        "workflowType",
                        "captureEventType",
                        "disableAllButton",
                        "confirm_selection",
                        "doctor_list_filter_placeholder_text"

                    ],
                },
                {
                    widgetName: "suggestions",
                    widgetFunc: (props) => <Suggestions {...props} />,
                    mapStateToProps: ["wsClient", "conversationId", "connectionStatus",
                        "disableAllButton"

                    ],
                },
                {
                    widgetName: "widget",
                    widgetFunc: (props) => <WidgetDocs {...props} />,
                    mapStateToProps: ["gist", "infoBox"],
                },
                {
                    widgetName: "updateButtons",
                    widgetFunc: (props) => <UpdateButtion {...props} />,
                    mapStateToProps: [
                        "wsClient",
                        "conversationId",
                        "connectionStatus",
                        "updateButtons",
                        "captureEventType",
                        "disableAllButton"

                    ],
                },

                {
                    widgetName: "restartConversation",
                    widgetFunc: (props) => <Reappointment {...props} />,
                    mapStateToProps: [
                        "wsClient",
                        "conversationId",
                        "connectionStatus",
                        "captureEventType",
                        "disableAllButton"

                    ],
                },
                {
                    widgetName: "askConfirmation",
                    widgetFunc: (props) => <AskConfirmation {...props} />,
                    mapStateToProps: [
                        "wsClient",
                        "conversationId",
                        "connectionStatus",
                        "captureEventType",
                        "workflowType",
                        "disableAllButton"

                    ],
                },
                {
                    widgetName: "updatePatientDetails",
                    widgetFunc: (props) => <PatientDetails {...props} />,
                    mapStateToProps: [
                        "wsClient",
                        "conversationId",
                        "connectionStatus",
                        "updatePatientDetails",
                        "workflowType",
                        "captureEventType",
                        "disableAllButton"


                    ],
                },
                {
                    widgetName: "patientscaneType",
                    widgetFunc: (props) => <Patientscanetype {...props} />,
                    mapStateToProps: [
                        "wsClient",
                        "conversationId",
                        "connectionStatus",
                        "updatePatientDetails",
                        "captureEventType",
                        "disableAllButton"

                    ],
                },
                {
                    widgetName: "fileUpload",
                    widgetFunc: (props) => <Fileupload {...props} />,
                    mapStateToProps: [
                        "wsClient",
                        "conversationId",
                        "connectionStatus",
                        "fileUploadButton",
                        "fileStore",
                        "disableAllButton"

                    ],
                },
                {
                    widgetName: "fileUploadButton",
                    widgetFunc: (props) => <Fileuploadbutton {...props} />,
                    mapStateToProps: [
                        "wsClient",
                        "conversationId",
                        "connectionStatus",
                        "fileUploadButton",
                        "fileStore",
                        "captureEventType",
                        "disableAllButton",
                        "confirmatrionText",
                        "attach_referral_again_text",
                        "referral_upload_options",
                        "send_link_to_mobile_message"

                    ],
                },
                {
                    widgetName: "CustomdatePicker",
                    widgetFunc: (props) => <Customdatepicker {...props} />,
                    mapStateToProps: [
                        "wsClient",
                        "conversationId",
                        "Date",
                        "workingDays",
                        "workflowType",
                        "captureEventType",
                        "disableAllButton",
                        "confirmatrionText"

                    ],
                },
                {
                    widgetName: "imagingMultiCenterCalendar",
                    widgetFunc: (props) => <ImagingMultiCenterCalendar {...props} />,
                    mapStateToProps: [
                        "wsClient",
                        "conversationId",
                        "Date",
                        "workingDays",
                        "workflowType",
                        "captureEventType",
                        "disableAllButton",
                        "confirmatrionText",
                        "multi_center_tab_options",
                        "selected_tab_id",
                        "event_to_be_returned_by_UI"

                    ],
                },
                {
                    widgetName: "WebSocketError",
                    widgetFunc: (props) => <WebSocketError {...props} />,
                    mapStateToProps: ["wsClient", "conversationId"],
                },

                {
                    widgetName: "timeOut",
                    widgetFunc: (props) => <TimeOutNotification {...props} />,
                    mapStateToProps: ["wsClient", "conversationId", "conversationCloseCountDownTime", "captureEventType"],
                },
                {
                    widgetName: "doctorAvailablity",
                    widgetFunc: (props) => <DoctorAvailablity {...props} />,
                    mapStateToProps: ["wsClient", "conversationId", "doctorAvailablityData",
                        "captureEventType",
                        "disableAllButton",
                        "confirm_selection",
                        "deny_selection",
                        "selected_time_text"
                    ],
                },
                {
                    widgetName: "showDatepicker",
                    widgetFunc: (props) => <GPCalendarWidget {...props} />,
                    mapStateToProps: ["wsClient", "conversationId", "workflowType", "captureEventType", "centerList"],


                },
                {
                    widgetName: "singleImagingCenterDatepicker",
                    widgetFunc: (props) => <ImagingCalendarWidget {...props}></ImagingCalendarWidget>,
                    mapStateToProps: ["wsClient", "conversationId", "workflowType", "captureEventType", "centerList","selected_tab_id"],

                },
                {
                    widgetName: "conversationTermination",
                    widgetFunc: (props) => <ConversationTermination {...props} />,
                    mapStateToProps: ["wsClient", "conversationId", "doctorAvailablityData",
                        "captureEventType",
                        "disableAllButton"
                    ],
                },
                {
                    widgetName: "options",
                    widgetFunc: (props) => <Options {...props} />,
                    mapStateToProps: ["wsClient", "conversationId",
                        "captureEventType",
                        "disableAllButton",
                        "defaultOptions",]
                },
                {
                    widgetName: "languageSelection",
                    widgetFunc: (props) => <LanguageSelection {...props} />,
                    mapStateToProps: [
                        "wsClient",
                        "conversationId",
                        "connectionStatus",
                        "defaultOptions",
                        "restartHandler",
                        "captureEventType",
                        "previousCaptureEventType",
                        "disableAllButton",
                        "showTheCountDown",
                        "conversationCloseCountDownTime",
                    ],
                },
                {
                    widgetName: "unKnownResponseType",
                    widgetFunc: (props) => <UnknownResponseTypeHandle {...props} />,
                    mapStateToProps: [
                        "wsClient",
                        "conversationId",
                        "connectionStatus",
                        "captureEventType",
                        "disableAllButton"

                    ],
                },

            ],
        };
        return config;
    }
}

export default BotConfig;