import React, { useState } from "react";
import CustomButton from "../../../UI/CustomButton/CustomButton";
import Fileupload from "../Fileupload/Fileupload";
import styles from "./Fileuploadbutton.module.css";
import { useSelector } from "react-redux";

const Fileuploadbutton = (props) => {
  const [disable, setDisable] = useState(false);
  const [image, setImage] = useState(null);
  const [sendImage, setSendImage] = useState(null);
  const [fileInput, setFileInput] = useState(null);
  const { connectionStatus} = useSelector((state) => state.Reducer);
  const {activeButtonColor,activeButtonTextColor,inactiveButtonColor,inactiveButtonTextColor} = useSelector((state) => state.Theme);
  const handleAIResponse = (webSocketMessage) => {
    let ai_response = JSON.parse(webSocketMessage.data);
    if (ai_response.type === "HANG_UP") {
      let message = {
        event: "CLOSE_CONVERSATION",
        uid: props.conversationId,
      };
      // props.wsClient.handle_User_CloseConnectionRequest();
      props.wsClient.handleUserResponse(message);
    }

    props.actionProvider.showResponseDialog(ai_response);
  };

  // NOTE:- this handleBtnEvent function does two thing 1. shows the user clicked response 2. send the data to server
  const handleBtnEvent = (option) => {
    props.actionProvider.showResponseDialog({
      type: "USER_TEXT",
      message: props.send_link_to_mobile_message,
    });

    // NOTE:- handleUserResponse method sends the info to server and on the bases of info server send back the info through webSocket message
    let message = {
      event: "NOTIFICATION_REFERRAL",
      uid: props.conversationId,
      data: "Send an upload link",
    };

    props.wsClient.handleUserResponse(message, handleAIResponse);

    // Disabling the button after clicking on the Send Upload link button  
    setDisable((prevState)=>!prevState)
  };
  const fileHandler = (e) => {
    const images = Array.from(e.target.files);
    setSendImage(images);
    setImage(e.target.files[0]);
    props.setState((prevState) => ({
      ...prevState,
      fileStore: e.target.files[0],
    }));
  };

  return (
    <center>
      <div className={styles.vengage__options} style={{ width: "85%" }}>
        <div className={styles.vengage__options__container}>
          <button
            disabled={connectionStatus!=""?true: disable}
            type="button"
            className={styles.vengage__btn__primary}
            onClick={() => fileInput.click()}
            style={{ backgroundColor:connectionStatus==""?((!disable ? (activeButtonColor!=null?activeButtonColor:null):inactiveButtonColor)):inactiveButtonColor,
              color:connectionStatus==""?(!disable ? (activeButtonTextColor!=null?activeButtonTextColor:null):inactiveButtonTextColor): inactiveButtonTextColor}}
          >
            { props.referral_upload_options && props.referral_upload_options[0].text}
            <input
              type="file"
              ref={(fileInputref) => setFileInput(fileInputref)}
              multiple
              onChange={fileHandler}
              style={{ display: "none" }}
            />
          </button>
          <CustomButton
            text={props.referral_upload_options && props.referral_upload_options[1].text}
            clickHandler={(e) => handleBtnEvent()}
            disabled={disable}
          />
        </div>
        {image !== null && (
          <Fileupload
            image={image}
            sendImage={sendImage}
            setSendImage={(val) => setSendImage(val)}
            setImage={(val) => setImage(val)}
            actionProvider={props.actionProvider}
            conversationId={props.conversationId}
            wsClient={props.wsClient}
            handleAIResponse={props.handleAIResponse}
            setDisable={(val) => setDisable(val)}
            attach_referral_again_text={props.attach_referral_again_text}
            confirmatrionText={props.confirmatrionText}
          />
        )}
      </div>
    </center>
  );
};

export default Fileuploadbutton;
