import { v4 as uuidv4 } from "uuid";
import parse from "html-react-parser";
import FileattatchLogo from "./widgets/Fileupload/fileattach.png";
import MessageLoading from "../assets/icons/loading.gif";
import LanguageStore from "../data/LanguageStore";
import errorIcon from "../bot/widgets/Fileupload/error.png";
class ActionProvider {
    constructor(createChatBotMessage, setStateFunc, createClientMessage) {
        this.createChatBotMessage = createChatBotMessage;
        this.createClientMessage = createClientMessage;
        this.setState = setStateFunc;
    }

    /*NOTE:- createChatBotMessage creates the message for chatBot and createClientMessage creats the message for user which ever data we pass
       createChatBotMessage takes two parameter 1. chatBot message and 2nd component which we want to show after the chatBot message
    
      */

    showResponseDialog = (aiResponse) => {
        console.log("🚀 ~ file: ActionProvider.js ~ line 22 ~ ActionProvider ~ aiResponse", aiResponse)

        // console.log("aiResponse-->",aiResponse.type)
        if (aiResponse.type === "PRESET" || aiResponse.type === "BUTTON") {
            aiResponse.type = "BUTTON_GROUP";
        }

        // on ping event , we are not doing anything, this is just to check the websocket is alive or not
        if (aiResponse.type === "PING") return;

        if (aiResponse.type === "SINGLE_LANGUAGE_OVERVIEW") {
            this.setState((prevState) => ({
                ...prevState,

                captureEventType: aiResponse.type,
                input_box_placeholder: aiResponse.textbox_placeholder_text,
            }));
            return;
        }
        // If we get CLOSE_CONVERSATION or "chat Disconnected" message . We will not show the message
        if (
            aiResponse.type === "CLOSE_CONVERSATION" || aiResponse?.message?.toLowerCase() === "chat disconnected"
        ) {
            this.setState((prevState) => ({
                ...prevState,

                workflowType: aiResponse.workflow_type,
                captureEventType: aiResponse.type,
                previousCaptureEventType: aiResponse.type,
                input_box_placeholder: aiResponse.textbox_placeholder_text,
            }));
            return;
        }

        if (aiResponse.message == "") {
            this.setState((prevState) => ({
                ...prevState,

                workflowType: aiResponse.workflow_type,
                captureEventType:
                    aiResponse.type != "CONVERSATION_INACTIVE_ALERT"
                        ? aiResponse.type
                        : "",
                previousCaptureEventType: aiResponse.type,
                input_box_placeholder: aiResponse.textbox_placeholder_text,
            }));

            if (aiResponse.type === "CONVERSATION_INACTIVE_ALERT") {
                this.setState((prevState) => ({
                    ...prevState,
                    conversationCloseCountDownTime:
                        aiResponse.terminate_conversation_countdown_in_seconds,
                    showTheCountDown: true,
                    // input_box_placeholder:aiResponse.textbox_placeholder_text,
                }));
            }
            return;
        }

        if (aiResponse.type === "SET_WEBSOCKET_TO_STATE") {
            this.setState((prevState) => ({
                ...prevState,

                wsClient: aiResponse.data,
            }));
            return;
        }

        // On restart yes/no we are not updating the previous captured event
        if (
            aiResponse.type === "RESETMESSAGE" ||
            aiResponse.type === "RELOAD" ||
            aiResponse.type === "ASK_CONFIRMATION"
        ) {
            this.setState((prevState) => ({
                ...prevState,
                captureEventType: aiResponse.type,
                showTheCountDown: false,
                conversationCloseCountDownTime: null,
                input_box_placeholder: aiResponse.textbox_placeholder_text,
            }));
        } else {
            // Capturing the Evrnt type , workflow type
            this.setState((prevState) => ({
                ...prevState,

                workflowType: aiResponse.workflow_type,
                captureEventType: aiResponse.type,
                previousCaptureEventType: aiResponse.type,
                showTheCountDown: false,
                conversationCloseCountDownTime: null,
                input_box_placeholder: aiResponse.textbox_placeholder_text,
            }));
            if (aiResponse.type === "CONVERSATION_TERMINATED_BY_CONV_ENGINE") {
                this.setState((prevState) => ({
                    ...prevState,

                    disableAllButton: true,
                }));
            }
        }

        switch (aiResponse.type) {
            case "TEXT":
            case "CONVERSATION_TERMINATED_BY_CONV_ENGINE":
            case "REFERRAL_UPLOAD_ERROR":

                //   case "REFERRAL_UPLOAD_ERROR":
                this.setState((prevState) => ({
                    ...prevState,
                    Switch_Input: "",
                    Mobile_No: "",
                    // workflowType: aiResponse.workflow_type,
                    // captureEventType: aiResponse.type,
                }));

                this.addMessageToBotState(
                    this.createChatBotMessage(
                        <span style={{ padding: "0px" }}>{parse(aiResponse.message)}</span>,
                        {
                            withAvatar: true,
                            loading: true,
                        }
                    )
                );
                break;
    

            case "IMG_EREFERAL_ASK_DOB":
            //   case "REFERRAL_UPLOAD_ERROR":
                this.setState((prevState) => ({
                ...prevState,
                event_to_be_returned_by_UI:aiResponse?.event_to_be_returned_by_UI?aiResponse.event_to_be_returned_by_UI:null,
                Switch_Input: "IMG_EREFERAL_ASK_DOB"
                }));

                this.addMessageToBotState(
                this.createChatBotMessage(
                    <span style={{ padding: "0px" }}>{parse(aiResponse.message)}</span>,
                    {
                    withAvatar: true,
                    loading: true,
                    }
                )
                );
                break;

            case "ASK_MOBILE":
                this.setState((prevState) => ({
                ...prevState,
                event_to_be_returned_by_UI:aiResponse?.event_to_be_returned_by_UI?aiResponse.event_to_be_returned_by_UI:null,
                Switch_Input: "ASK_MOBILE"
                }));

                this.addMessageToBotState(
                this.createChatBotMessage(
                    <span style={{ padding: "0px" }}>{parse(aiResponse.message)}</span>,
                    {
                    withAvatar: true,
                    loading: true,
                    }
                )
                );
                break;
        
            case "LOADING_MESSAGE":
                this.addMessageToBotState(
                    this.createChatBotMessage(<span>Loading ....</span>)
                );
                break;

            case "ERROR":
                this.addMessageToBotState(
                    this.createChatBotMessage(<span>Failed to send the file.</span>)
                );
                break;

            case "PRESET":
                this.addMessageToBotState(
                    this.createChatBotMessage(
                        <span style={{ padding: "0px" }}>{parse(aiResponse.message)}</span>
                    )
                );
                break;
            case "HANG_UP":
                this.setState((prevState) => ({
                    ...prevState,
                    captureEventType: aiResponse.type,
                    input_box_placeholder: aiResponse.textbox_placeholder_text,
                }));
                //Show the message if message is not null
                if (aiResponse.message) {
                    this.addMessageToBotState(
                        this.createChatBotMessage(
                            <span style={{ padding: "0px" }}>
                                {parse(aiResponse.message)}
                            </span>,
                            {
                                withAvatar: true,
                                loading: true,
                            }
                        )
                    );
                }

                break;
            case "ASK_MOBILE":
                this.setState((prevState) => ({
                    ...prevState,
                    captureEventType: aiResponse.type,
                    Mobile_No: "ASK_MOBILE",
                    input_box_placeholder: aiResponse.textbox_placeholder_text,

                    workflowType: aiResponse.workflow_type,
                    // captureEventType: aiResponse.type,
                }));
                this.addMessageToBotState(
                    this.createChatBotMessage(
                        <span style={{ padding: "0px" }}>{parse(aiResponse.message)}</span>
                    )
                );
                break;
            case "GP_ASK_MOBILE":
                this.setState((prevState) => ({
                    ...prevState,
                    Mobile_No: "GP_ASK_MOBILE",
                    input_box_placeholder: aiResponse.textbox_placeholder_text,

                    workflowType: aiResponse.workflow_type,
                    captureEventType: aiResponse.type,
                }));
                this.addMessageToBotState(
                    this.createChatBotMessage(
                        <span style={{ padding: "0px" }}>{parse(aiResponse.message)}</span>
                    )
                );
                break;

            case "UPLOAD":
                this.setState((prevState) => ({
                    ...prevState,
                    // captureEventType: aiResponse.type,
                    fileUploadButton: aiResponse.preset_responses,
                    input_box_placeholder: aiResponse.textbox_placeholder_text,
                    confirmatrionText: aiResponse.confirm_button_text,
                    attach_referral_again_text: aiResponse.attach_referral_again_text,
                    referral_upload_options: aiResponse.preset_responses,
                    send_link_to_mobile_message: aiResponse.send_link_to_mobile_message,

                    // workflowType: aiResponse.workflow_type,
                }));
                this.addMessageToBotState(
                    this.createChatBotMessage(
                        <span style={{ padding: "0px" }}>{parse(aiResponse.message)}</span>,
                        {
                            widget: "fileUploadButton",
                            withAvatar: true,
                            loading: true,
                        }
                    )
                );
                break;

            case "SHOWFILE":
                if (aiResponse.image.type == "application/pdf") {
                    this.addMessageToBotState(
                        this.createClientMessage(
                            <span>
                                <img
                                    src={FileattatchLogo}
                                    alt="file"
                                    style={{ width: "80px", height: "80px" }}
                                />
                            </span>
                        )
                    );
                } else {
                    this.addMessageToBotState(
                        this.createClientMessage(
                            <span>
                                <img
                                    src={URL.createObjectURL(aiResponse.image)}
                                    alt="file"
                                    style={{ width: "80px", height: "80px" }}
                                />
                            </span>
                        )
                    );
                }
                break;
            case "RESETMESSAGE":
                this.setState((prevState) => {
                    let tempArr = [...prevState.messages];
                    tempArr.splice(-1, 1);
                    return {
                        ...prevState,
                        restartHandler: false,
                        messages: [...tempArr],
                    };
                });

                break;
                case "DENY_RESET_SCAN_DETAILS":
                    this.setState((prevState) => {
                        let tempArr = [...prevState.messages];
                        tempArr.splice(-1, 1);
                        return {
                        ...prevState,
                        header: "compressed",
                        Switch_Input: "",
                        restartHandler: false,
                        messages: [...tempArr],
                    }});
                    {
                        this.addMessageToBotState(
                            this.createClientMessage(
                                <span style={{ padding: "0px" }}>
                                    {parse(aiResponse.message)}
                                </span>
                            )
                        );
                    }
                    break;
            case "RELOAD":
                //this.addMessageToBotState("RESET_MESSAGE");
                this.setState((prevState) => ({
                    ...prevState,
                    captureEventType: "DISABLE_INPUT_FIELD",
                }));
                this.addMessageToBotState(
                    this.createChatBotMessage("Reset Converstion", {
                        widget: "restartConversation",
                        withAvatar: true,
                        loading: true,
                    })
                );

                break;
            case "ASK_CONFIRMATION":
                //this.addMessageToBotState("RESET_MESSAGE");
                this.setState((prevState) => ({
                    ...prevState,
                    captureEventType: "DISABLE_INPUT_FIELD",
                    // input_box_placeholder:aiResponse.textbox_placeholder_text,
                }));
                this.addMessageToBotState(
                    this.createChatBotMessage("Reset Converstion", {
                        widget: "askConfirmation",
                        withAvatar: true,
                        loading: true,
                    })
                );

                break;

            case "RESTARTCONVERASTION":
                // console.log("PRESET_DEFAULT_RENDERED2")

                this.addMessageToBotState("RESET_MESSAGE");
                let numberOfLanguagesSupported = LanguageStore.languages.length;
                if (numberOfLanguagesSupported === 1) {
                    this.addMessageToBotState(
                        this.createChatBotMessage(
                            <span style={{ padding: "0px" }}>
                                {parse(aiResponse.message)}
                            </span>,
                            {
                                widget: "overview",
                                withAvatar: true,
                                loading: true,
                            }
                        )
                    );
                } else {
                    this.addMessageToBotState(
                        this.createChatBotMessage(
                            <span style={{ padding: "0px" }}>
                                {parse(aiResponse.message)}
                            </span>,
                            {
                                widget: "languageSelection",
                                withAvatar: true,
                                loading: true,
                            }
                        )
                    );
                }

                break;
            case "RESTART_WITH_NEW_CONNECTION":
                this.setState((prevState) => ({
                    ...prevState,
                    captureEventType: "",
                }));
                this.addMessageToBotState("RESET_MESSAGE_NEWCONNECTION");
                this.addMessageToBotState(
                    this.createChatBotMessage(
                        parse(
                            "Hi, my name is <b>Olivia</b>. Please select one of the options"
                        ),
                        {
                            widget: "overview",
                            withAvatar: true,
                            loading: true,
                        }
                    )
                );
                break;
            case "REMOVE_LAST_MSG":
                this.setState((prevState) => {
                    let tempArr = [...prevState.messages];
                    tempArr.splice(-1, 1);
                    return {
                        ...prevState,
                        messages: [...tempArr],
                    };
                });

                break;

            case "ERROR_PAGE":
                this.setState((prevState) => ({
                    ...prevState,
                    captureEventType: "DISABLE_INPUT_FIELD",
                }));
                this.addMessageToBotState(
                    this.createChatBotMessage("Reset Converstion", {
                        widget: "WebSocketError",
                        withAvatar: true,
                        loading: true,
                    })
                );
                break;
            case "NETWORK_ERROR":
                this.setState((prevState) => ({
                    ...prevState,
                    captureEventType: "DISABLE_INPUT_FIELD",
                }));
                this.addMessageToBotState(
                    this.createChatBotMessage("Reset Converstion", {
                        widget: "netWorkError",
                        withAvatar: true,
                        loading: true,
                    })
                );
                break;

            case "TIME_OUT_SESSION":
                // case "CONVERSATION_INACTIVE_ALERT":
                this.setState((prevState) => ({
                    ...prevState,
                    captureEventType: aiResponse.type,
                }));

                this.addMessageToBotState(
                    this.createChatBotMessage("Reset Converstion", {
                        widget: "timeOut",
                        withAvatar: true,
                        loading: true,
                    })
                );
                break;

            case "PRESET_DEFAULT":
                //Removing the First two msg
                this.setState((prevState) => {
                    let tempArr = [...prevState.messages];
                    tempArr.splice(0, 2);
                    return {
                        ...prevState,
                        messages: [...tempArr],
                    };
                });

                this.welcomeMessage = aiResponse.message;
                // LanguageStore.languageText.splice(0, LanguageStore.languageText.length);
                // LanguageStore.languageText.push(aiResponse.message)
                LanguageStore.welcomeMessage.push(aiResponse.message);

                // localStorage.setItem("welcome_message", aiResponse.message)
                this.setState((prevState) => ({
                    ...prevState,
                    // captureEventType: aiResponse.type,
                    defaultOptions: aiResponse.preset_responses,
                    restartHandler: true,

                    captureEventType: aiResponse.type,
                    input_box_placeholder: aiResponse.textbox_placeholder_text,
                    meet_olivia_text: aiResponse.meet_olivia_text,
                    restart_chat_text: aiResponse.restart_chat_text,
                    // input_box_placeholder:"कृपया उपरोक्त विकल्पों में से एक का चयन करें",
                    // meet_olivia_text:"ओलिविया से मिलें",
                    // restart_chat_text:"चैट को पुनरारंभ करें"
                }));

                let appointmentmsg = this.createChatBotMessage(
                    <span style={{ padding: "0px" }}>{parse(aiResponse.message)}</span>,
                    {
                        widget: "overview",
                        withAvatar: true,
                        loading: true,
                        // componentData: aiResponse.preset_responses,
                    }
                );
                this.addMessageToBotState(appointmentmsg);
                break;

            case "PRESET_CARDIAC_OPTIONS":
                this.setState((prevState) => ({
                    ...prevState,
                    // captureEventType: aiResponse.type,
                    defaultOptions: aiResponse.preset_responses,
                    captureEventType: aiResponse.type,
                }));
                const message = this.createChatBotMessage(
                    <span style={{ padding: "0px" }}>{parse(aiResponse.message)}</span>,
                    {
                        widget: "options",
                        withAvatar: true,
                        loading: true,
                        // componentData: aiResponse.preset_responses,
                    }
                );
                this.addMessageToBotState(message);

                break;

            // This case runs when user select an option or type a message
            case "USER_TEXT":
                // console.log("CAEEEEE", aiResponse);
                // console.log("aiResponse.message", aiResponse.message);

                //NOTE:- did not get header meaning
                this.setState((prevState) => ({
                    ...prevState,
                    header: "compressed",
                    Switch_Input: "",
                }));
                // NOTE:- createClientMessage method create a User response to Show in chatBot
                // NOTE:-  ALl these messages stores in message array of chatBot
                // this.createChatBotMessage method create a message for user
                // NOTE:- if yes is clicked the show the Center list
                {
                    this.addMessageToBotState(
                        this.createClientMessage(
                            <span style={{ padding: "0px" }}>
                                {parse(aiResponse.message)}
                            </span>
                        )
                    );
                }
                break;
            case "REMOVE_FIRST_MSG":
                this.setState((prevState) => {
                    let tempArr = [...prevState.messages];
                    tempArr.splice(0, 1);
                    return {
                        ...prevState,
                        messages: [...tempArr],
                    };
                });

                break;
            case "LANGUAGE_SELECTION":
                // console.log("CAEEEEE", aiResponse);
                // console.log("aiResponse.message", aiResponse.message);

                //NOTE:- did not get header meaning
                this.setState((prevState) => ({
                    ...prevState,
                    header: "compressed",
                    Switch_Input: "",
                }));
                // NOTE:- createClientMessage method create a User response to Show in chatBot
                // NOTE:-  ALl these messages stores in message array of chatBot
                // this.createChatBotMessage method create a message for user
                // NOTE:- if yes is clicked the show the Center list
                {
                    this.addMessageToBotState(
                        this.createClientMessage(
                            <span style={{ padding: "0px" }}>
                                {parse(aiResponse.message)}
                            </span>
                        )
                    );
                }
                break;

            case "ASK_NAME":
                // console.log("ASK_NAME RECEIVED", aiResponse.message);
                this.setState((prevState) => ({
                    ...prevState,
                    // captureEventType: aiResponse.type,
                    Switch_Input: "ASK_NAME",
                    input_box_placeholder: aiResponse.textbox_placeholder_text,

                    // captureEventType: aiResponse.type,
                }));

                this.addMessageToBotState(
                    this.createChatBotMessage(
                        <span style={{ padding: "0px" }}>{parse(aiResponse.message)}</span>
                    )
                );
                break;
            case "PATIENT_DEMOGRAPHY":
                // console.log("PATIENT_DEMOGRAPHY RECEIVED", aiResponse.message);
                this.setState((prevState) => ({
                    ...prevState,
                    Switch_Input: "PATIENT_DEMOGRAPHY",
                    input_box_placeholder: aiResponse.textbox_placeholder_text,

                    // captureEventType: aiResponse.type,
                }));

                this.addMessageToBotState(
                    this.createChatBotMessage(
                        <span style={{ padding: "0px" }}>{parse(aiResponse.message)}</span>
                    )
                );
                break;

            case "BUTTON_GROUP":
                this.setState((prevState) => ({
                    ...prevState,
                    // captureEventType: aiResponse.type,
                    modalityConfirmButton: aiResponse.preset_responses,
                    Mobile_No: "",
                    input_box_placeholder: aiResponse.textbox_placeholder_text,

                    // workflowType: aiResponse.workflow_type,
                    // captureEventType: aiResponse.type,
                }));
                aiResponse.type = "bot";
                const btnMessages = this.createChatBotMessage(
                    <span style={{ padding: "0px" }}>{parse(aiResponse.message)}</span>,
                    {
                        widget: "btnOptions",
                        withAvatar: true,
                        loading: true,
                        // componentData: aiResponse.preset_responses,
                    }
                );
                this.addMessageToBotState(btnMessages);
                break;
            case "UPDATE_BUTTONS":
                // updateButtons
                this.setState((prevState) => ({
                    ...prevState,
                    // captureEventType: aiResponse.type,
                    updateButtons: aiResponse.preset_responses,
                    // workflowType: aiResponse.workflow_type,
                }));
                const updatebtnMessages = this.createChatBotMessage(
                    <span style={{ padding: "0px" }}>{parse(aiResponse.message)}</span>,
                    {
                        widget: "updateButtons",
                        withAvatar: true,
                        loading: true,
                        // componentData: aiResponse.preset_responses,
                    }
                );
                this.addMessageToBotState(updatebtnMessages);
                break;



            case "DATE_PICKER":
                this.setState((prevState) => ({
                    ...prevState,
                    // captureEventType: aiResponse.type,
                    Date: aiResponse.date,
                    workingDays: aiResponse.working_days,
                    input_box_placeholder: aiResponse.textbox_placeholder_text,
                    confirmatrionText: aiResponse.confirm_response,

                    // workflowType: aiResponse.workflow_type,
                    // captureEventType: aiResponse.type,
                }));
                const dayList = this.createChatBotMessage(
                    <span style={{ padding: "0px" }}>{parse(aiResponse.message)}</span>,
                    {
                        widget: "CustomdatePicker",
                        withAvatar: true,
                        loading: true,
                    }
                );
                this.addMessageToBotState(dayList);
                break;
            case "DATE_PICKER_IMAGING_SINGLE_CENTER":
                console.log('aiResponse.message = ', aiResponse.message)
                this.setState((prevState) => ({
                    ...prevState,
                    // captureEventType: aiResponse.type,
                    Date: aiResponse.date,
                    workingDays: aiResponse.working_days,
                    input_box_placeholder: aiResponse.textbox_placeholder_text,
                    confirmatrionText: aiResponse.confirm_response,

                    // workflowType: aiResponse.workflow_type,
                    // captureEventType: aiResponse.type,
                }));
                const dayList_IMGSingleCenter = this.createChatBotMessage(
                    <span style={{ padding: "0px" }}>{parse(aiResponse.message)}</span>,
                    {
                        widget: "CustomdatePicker",
                        withAvatar: true,
                        loading: true,
                    }
                );
                this.addMessageToBotState(dayList_IMGSingleCenter);
                break;
            case "DATE_PICKER_IMAGING_MULTI_CENTER":
                this.setState((prevState) => ({
                    ...prevState,
                    // captureEventType: aiResponse.type,
                    Date: aiResponse.date,
                    workingDays: aiResponse.working_days,
                    input_box_placeholder: aiResponse.textbox_placeholder_text,
                    confirmatrionText: aiResponse.confirm_response,
                    multi_center_tab_options: aiResponse.center_options,
                    selected_tab_id: aiResponse.currently_selected_center_id,
                    event_to_be_returned_by_UI: aiResponse?.event_to_be_returned_by_UI ? aiResponse.event_to_be_returned_by_UI : null

                    // workflowType: aiResponse.workflow_type,
                    // captureEventType: aiResponse.type,
                }));
                const dayList_IMGMultiCenter = this.createChatBotMessage(
                    <span style={{ padding: "0px" }}>{parse(aiResponse.message)}</span>,
                    {
                        widget: "imagingMultiCenterCalendar",
                        withAvatar: true,
                        loading: true,
                    }
                );
                this.addMessageToBotState(dayList_IMGMultiCenter);
                break;
            //this  OVERVIEW case runs when in messageParser component type :"OVERVIEW" comes
            case "OVERVIEW":
                this.setState((prevState) => ({
                    ...prevState,
                    // captureEventType: aiResponse.type,
                    userDetails: aiResponse,
                    // workflowType: aiResponse.workflow_type,
                    // captureEventType: aiResponse.type,
                }));
                aiResponse.type = "bot";
                // this overview variable runs two message 1. aiResponse.message and 2nd bookingOverview component
                const overview = this.createChatBotMessage(
                    <span style={{ padding: "0px" }}>{parse(aiResponse.message)}</span>,
                    {
                        widget: "bookingOverview",
                        withAvatar: true,
                        loading: true,
                        // this data is passed to bookingOverview component
                    }
                );
                this.addMessageToBotState(overview);
                break;

            case "TILES":
                // console.log("CENTER", aiResponse.preset_responses);
                this.setState((prevState) => ({
                    ...prevState,
                    // captureEventType: aiResponse.type,
                    centerList: aiResponse.preset_responses,
                    input_box_placeholder: aiResponse.textbox_placeholder_text,

                    // workflowType: aiResponse.workflow_type,
                    // captureEventType: aiResponse.type,
                }));
                let cityList = this.createChatBotMessage(
                    <span style={{ padding: "0px" }}>{parse(aiResponse.message)}</span>,
                    {
                        widget: "citySelection",
                        withAvatar: true,
                        loading: true,
                        // componentData: aiResponse.workflow_type,

                        // NOTE:- here we are passing the data to component using componentData key
                        // componentData: aiResponse.preset_responses,
                    }
                );

                this.addMessageToBotState(cityList);
                break;
            case "DOCTOR_LIST":
                // console.log("CENTER", aiResponse.preset_responses);
                this.setState((prevState) => ({
                    ...prevState,
                    centerList: aiResponse.preset_responses,
                    workflowType: aiResponse.workflow_type,
                    captureEventType: aiResponse.type,
                    input_box_placeholder: aiResponse.textbox_placeholder_text,
                    confirm_selection: aiResponse.confirm_selection,
                    doctor_list_filter_placeholder_text:
                        aiResponse.doctor_list_filter_placeholder_text,
                }));
                let doctorList = this.createChatBotMessage(
                    <span style={{ padding: "0px" }}>{parse(aiResponse.message)}</span>,
                    {
                        widget: "citySelection",
                        withAvatar: true,
                        loading: true,

                        // componentData: aiResponse.workflow_type,

                        // NOTE:- here we are passing the data to component using componentData key
                        // componentData: aiResponse.preset_responses,
                    }
                );

                this.addMessageToBotState(doctorList);
                break;

            case "DOCTORWISE_AVAILABLE_SLOTS":
                this.setState((prevState) => ({
                    ...prevState,
                    doctorAvailablityData: aiResponse.preset_responses,
                    confirm_selection: aiResponse.confirm_selection,
                    deny_selection: aiResponse.deny_selection,
                    selected_time_text: aiResponse.selected_time_text,
                    // workflowType: aiResponse.workflow_type,

                    // captureEventType: aiResponse.type,
                }));
                let doctorAvailable = this.createChatBotMessage(
                    <span>{parse(aiResponse.message)}</span>,
                    {
                        widget: "doctorAvailablity",
                        withAvatar: true,
                        loading: true,
                    }
                );
                this.addMessageToBotState(doctorAvailable);
                break;

            case "INPUT":
                this.setState((prevState) => ({
                    ...prevState,
                    // captureEventType: aiResponse.type,
                    updatePatientDetails: aiResponse,
                    // captureEventType: aiResponse.type,
                }));

                if (aiResponse.component_identifier == "patient_info") {
                    let patientUpdate = this.createChatBotMessage(aiResponse.message, {
                        widget: "updatePatientDetails",
                    });

                    this.addMessageToBotState(patientUpdate);
                }
                if (aiResponse.component_identifier === "scan_info") {
                    let patientUpdate = this.createChatBotMessage(aiResponse.message, {
                        widget: "patientscaneType",
                    });

                    this.addMessageToBotState(patientUpdate);
                }

                break;

            case "SHOW_DATEPICKER":
            case "SHOW_TAB_DATEPICKER":
                this.setState((prevState) => ({
                    ...prevState,
                    captureEventType: aiResponse.type,
                    centerList: aiResponse.preset_responses,
                }));
                let showDatePicker = this.createChatBotMessage(aiResponse.message, {
                    widget: "showDatepicker",
                });
                this.addMessageToBotState(showDatePicker);
                break;

            // Show the widget with IN-PROGRESS GIF.
            case "SHOW_IMAGING_CENTER_DATEPICKER":
                this.setState((prevState) => ({
                    ...prevState,
                    captureEventType: aiResponse.type,
                    centerList: aiResponse.center_list,
                    selected_tab_id: aiResponse?.selected_center_id
                }));
                let single_imaging_show_date_picker = this.createChatBotMessage(aiResponse.message, {
                    widget: "singleImagingCenterDatepicker",
                });

                this.addMessageToBotState(single_imaging_show_date_picker);
                break;
            case "CALENDAR_IMAGING_MULTI_CENTER_WORKFLOW":
            case "IMAGING_SLOT_SELECTION":
                this.setState((prevState) => ({
                    ...prevState,
                    availableSlots: aiResponse,
                    Date: aiResponse.query_date,
                    // captureEventType: aiResponse.type,
                    center_id: aiResponse.center_id,
                    Switch_Input: "SWITCH_INPUT",
                    event_to_be_returned_by_UI: aiResponse?.event_to_be_returned_by_UI ? aiResponse.event_to_be_returned_by_UI : null
                    // workflowType: aiResponse.workflow_type,
                    // captureEventType: aiResponse.type,
                }));

                aiResponse.type = "bot";
                var multiCenterInfo = this.createChatBotMessage(
                    <span style={{ padding: "0px" }}>{parse(aiResponse.message)}</span>,
                    {
                        widget: "multiCenterTimePicker",
                        withAvatar: true,
                        loading: true,
                        //here we are passing the data to calender component
                        // componentData: aiResponse,
                    }
                );
                this.addMessageToBotState(multiCenterInfo);
                break;

            default:
                console.log('I AM HERE')
                this.setState((prevState) => ({
                    ...prevState,
                    captureEventType: "DISABLE_INPUT_FIELD",
                }));
                this.addMessageToBotState(
                    this.createChatBotMessage("Reset Converstion", {
                        widget: "unKnownResponseType",
                        withAvatar: true,
                        loading: true,
                    })
                );

                break;

        }
    };

    // NOTE:- addMessageToBotState  method addes the all messages to me Bot state

    addMessageToBotState = (messages, newState) => {
        if (messages === "RESET_MESSAGE") {
            this.setState((state) => ({
                ...state,
                presetUserResponses: [],
                chatBotHeaderDefault: true,
                step: "",
                header: "",
                defaultOptions: [],
                connectedClients: 0,

                connectionStatus: "Not Connected",
                // wsClient: this.createNewInstance(),
                modalityConfirmButton: "",
                centerList: "",
                availableSlots: "",
                userDetails: "",
                updateButtons: "",
                restartHandler: false,
                conversationId: uuidv4(),
                messages: [],
                captureEventType: "",
                disableAllButton: false,
                input_box_placeholder: "",
                meet_olivia_text: "",
                restart_chat_text: "",
            }));
            return;
        }
        if (messages === "RESET_MESSAGE_NEWCONNECTION") {
            this.setState((state) => ({
                ...state,
                presetUserResponses: [],
                chatBotHeaderDefault: true,
                step: "",
                header: "",
                defaultOptions: [],
                connectedClients: 0,
                connectionStatus: "Not Connected",
                // wsClient: this.createNewInstance(),
                modalityConfirmButton: "",
                centerList: "",
                availableSlots: "",
                userDetails: "",
                updateButtons: "",
                restartHandler: true,
                conversationId: uuidv4(),
                messages: [],
                captureEventType: "",
                disableAllButton: false,
                input_box_placeholder: "",
                meet_olivia_text: "",
                restart_chat_text: "",
            }));
            return;
        }

        if (Array.isArray(messages)) {
            this.setState((state) => ({
                ...state,
                ...newState,
                messages: [...state.messages, ...messages],
            }));
        } else if (messages != "RESET_MESSAGE") {
            this.setState((state) => ({
                ...state,
                ...newState,
                messages: [...state.messages, messages],
            }));
        } else if (messages === "RESET_MESSAGE_NEWCONNECTION") {
            this.setState((state) => ({
                ...state,
                ...newState,
                messages: [...state.messages, messages],
            }));
        }
    };
}

export default ActionProvider;
